import AxiosBackend from "@/config/backend-service";

function detectBrowser() {
    const userAgent = navigator.userAgent;

    if (userAgent.includes("Line")) {
        return "LINE In-App Browser";
    } else if (userAgent.includes("Chrome")) {
        return "Google Chrome";
    } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
        return "Safari";
    } else if (userAgent.includes("Firefox")) {
        return "Firefox";
    } else if (userAgent.includes("MSIE") || userAgent.includes("Trident")) {
        return "Internet Explorer";
    } else if (userAgent.includes("Edg")) {
        return "Microsoft Edge";
    } else {
        return "Unknown Browser";
    }
}

function addParamAndReload() {
    const url = new URL(window.location.href);
    const browser = detectBrowser();

    // ตรวจสอบว่ามีพารามิเตอร์อยู่แล้วหรือไม่
    if (!url.searchParams.has("openExternalBrowser")) {
        if (browser == "LINE In-App Browser") {
            url.searchParams.set("openExternalBrowser", "1");
            window.location.href = url.toString(); // โหลดหน้าใหม่พร้อมพารามิเตอร์
        }
    }
}

async function logVisit(to, param) {
    try {
        let body = {
            "site_id": param?.site?.id || null,
            "page": to?.name || null,
            "employee_code": param?.customer?.code || null,
            "mission_id": null,
            "employee_mission_id": null,
            "reward_id": null,
            "blog_id": null,
            "mission_status": null,
            "mission_category": null,
            "reward_category": null,
            "delivery_status": null,
        }
        const visitLog = to?.meta?.visitLog;
        const params_map = visitLog?.params;
        const route_params = to?.params;
        if (params_map) {
            for (let key in params_map) {
                const value = params_map[key];
                const value_param = route_params[key];
                body[value] = value_param;
            }
        }

        // const test = true;
        // if (test) return;

        AxiosBackend.post("createEmployeeVisitLog", { ...body })
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log("createEmployeeVisitLog err :>> ", err);
            });
    } catch (err) { console.error("logVisit", err) }
}

export {
    addParamAndReload,
    detectBrowser,
    logVisit,
}